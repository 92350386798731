<template>
  <div>
    <h3>Pengaduan</h3>
    <br />
    <CCard>
      <CCardBody>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <button
                class="btn btn-sm btn-primary ml-2"
                @click="addModal()"
              >
                <CIcon name="cilMedicalCross" />
                Tambah
              </button>

              <!-- <button
                class="btn btn-sm btn-primary ml-2"
                @click="addModalImport()"
              >
                <CIcon name="cilArrowThickToBottom" />
                Import
              </button> -->

              <!-- <button
                class="btn btn-sm btn-primary ml-2"
                v-if="user.role.name.toLowerCase() == 'admin'"
                @click="addFromApi()"
              >
                <CIcon name="cilMedicalCross" />
                Sinkron LKPP
              </button> -->

              <!-- <button
                  class="btn btn-sm btn-success mr-2 ml-2"
                >
                  <CIcon name="cil-spreadsheet" />
                  Export Excel
              </button>
              <button class="btn btn-sm btn-danger">
                <CIcon name="cib-adobe-acrobat-reader" />
                Export PDF
              </button> -->

              <label class="m-1 ml-3" for="">Select All : </label>
              <input
                type="checkbox"
                v-model="isSelectedAll"
                @change="checkAll()"
              />

              <select
                v-if="selectedItems.length > 0"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
                v-model="selectedAction"
                @change="changeActionSelected()"
              >
                <option value="0">Action Selected</option>
                <option value="1">Delete Items Selected</option>
                <!-- <option value="2">Export Excel Items Selected</option>
                <option value="3">Export Pdf Items Selected</option> -->
              </select>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData()"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
  
            <select
                style="max-width: 200px"
                class="form-control form-control-sm mx-2 col"
                placeholder="Ketik disini"
                v-model="params.kecamatan"
                @change="getData()"
              >
                <option value="">Kecamatan</option>
                <option
                  v-for="kecamatan in kecamatans"
                  :value="kecamatan.name"
                  :key="kecamatan.name"
                >
                  {{ kecamatan.name }}
                </option>
              </select>

              <select
                style="max-width: 100px"
                class="form-control form-control-sm mx-2 col"
                placeholder="Ketik disini"
                v-model="params.year"
                @change="getData()"
              >
                <option selected>2024</option>
                <option >2023</option>
                <option >2022</option>
                <option>2021</option>
                <option>2020</option>
              </select>

              <select
             
                class="form-control form-control-sm mx-2 col-md-2"
                placeholder="Ketik disini"
                v-model="params.status_pekerjaan"
                @change="getData()"
              >
                <option value="">Status</option>
                <option value="Belum Dilaksanakan">Belum Dilaksanakan</option>
                <option value="Sudah Dilaksanakan">Sudah Dilaksanakan</option>
              </select>

              <!-- <select
           
                class="form-control form-control-sm mx-2 col"
                placeholder="Ketik disini"
                v-model="params.jenis_lahan"
                @change="getData()"
              >
                <option value="">Semua Jenis Lahan</option>
                <option value="LSD">LSD</option>
                <option value="BUKAN LSD"> BUKAN LSD</option>
              </select> -->
               
              <!-- jenis prasarana -->
              <!-- <select
  
                class="form-control form-control-sm mx-2 col"
                placeholder="Ketik disini"
                v-model="params.jenis_pekerjaan"
                @change="getData()"
              >
                <option value="">Semua Jenis Prasarana</option>
                <option
                  v-for="cate in category"
                  :value="cate.name"
                  :key="cate.name"
                >
                  {{ cate.name }}
                </option>
              </select> -->
              <!-- jenis prasarana -->   
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>

        <v-container>
          <!-- <v-col>
              we use this wrapper to show the second scroll above of table
              <div id="wrapper2">
                <div id="div2" class="width-scroll" >
                </div>
              </div>
          </v-col> -->
          <v-col>
            <CDataTable
              class="table-striped"
              :items="computedItems"
              :fields="fields"
              sorter
            >
              <template #select="{ item }">
                <td class="py-2">
                  <input
                    type="checkbox"
                    @change="check(item)"
                    v-model="item.select"
                  />
              </td>
            </template>
            <!-- <template #progress="{ item }">
                <td class="py-2">
                  <CButton
                    @click="progress(item)"
                    class="mr-2"
                    :color="item.paket_pekerjaan_progress.length > 0 ? 'primary' : 'secondary'"
                    square
                    size="sm"
                  >
                    Update Progress
                  </CButton>
                  <CButton 
                    v-if="item.paket_pekerjaan_progress.length > 0"
                    @click="viewKurva(item)"
                    class="mr-2"
                    color="primary"
                    square
                    size="sm"
                  >
                    Kurva S
                  </CButton>
                </td>
              </template> -->
              <template #action="{ item }">
                <td class="py-2">
                  <CButton
                    @click="edit(item)"
                    class="mr-2"
                    color="warning"
                    square
                    size="sm"
                  >
                    Edit
                  </CButton>
                  <CButton @click="hapus(item)" color="danger" square size="sm">
                    Delete
                  </CButton>
                </td>
              </template>
            </CDataTable>
            <pagination
              v-if="total !== items.length"
              v-model="page"
              :records="total"
              :per-page="50"
              @paginate="pagination"
            />
          </v-col>  
        </v-container>

      </CCardBody>
    </CCard>

    <CModal 
      size="lg"
      :title="isUpdate ? 'Edit Pengaduan' : 'Tambah Pengaduan'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.sumber_dana"
            label="Nama Pengadu"
            placeholder="ketik disini"
            :disabled="isUpdate"
          />
          <CInput
            v-model="form.pemenang_tender"
            label="No. Telepon"
            placeholder="ketik disini"
            :disabled="isUpdate"
          />
          <CInput
            v-model="form.alamat_pekerjaan"
            label="Alamat Pengadu"
            placeholder="ketik disini"
            :disabled="isUpdate"
          />
        </div>
        <div class="col">
          <label for="kecamatan-select">Kecamatan</label>
          <v-select
            v-model="form.kecamatan"
            :options="kecamatans"
            label="name"
            placeholder="Pilih"
            class="form-control"
            id="kecamatan-select"
            :disabled="isUpdate"
          >
          </v-select>
          <br />
          <CTextarea
            v-model="form.nama_paket"
            label="Isi Laporan"
            placeholder="ketik disini"
            :disabled="isUpdate"
          />
          <label for="status-select">Status</label>
          <v-select
            v-model="form.status_pekerjaan"
            :options="optionStatus"
            label="name"
            class="form-control"
            id="status-select"
            placeholder="Pilih salah satu"
          >
          </v-select>

          <!-- <select
            name=""
            v-model="form.kecamatan"
            class="form-control"
            placeholder="Pilih"
            id=""
          >
            <option
              v-for="kecamatan in kecamatans"
              :value="kecamatan.name"
              :key="kecamatan.name"
            >
              {{ kecamatan.name }}
            </option>
          </select> -->
          <br />
          <!-- <select
            name=""
            v-model="form.status_pekerjaan"
            class="form-control"
            placeholder="Pilih"
            id=""
          >
            <option
              v-for="dd in desa"
              :value="dd"
              :key="dd + (Math.random())"
            >
              {{ dd }}
            </option>
          </select> -->
          <br />
        </div>
      </div>

      <input type="text" id="search" placeholder="Search for a place" class="form-control mb-3" v-model="searchQuery" @input="searchPlace" :disabled="isUpdate"/>
      <div id="map" style="height: 300px;"></div>

      <div class="row mt-3">
        <div class="col">
          <CInput
            v-model="form.longitude"
            label="Longitude"
            placeholder="ketik disini"
            :disabled="isUpdate"
          />
        </div>
        <div class="col">
          <CInput
            v-model="form.latitude"
            label="Latitude"
            placeholder="ketik disini"
            :disabled="isUpdate"
          />
        </div>
      </div>

      <!-- bagian upload -->
      <div class="row">
        <div class="col text-center">
          <label for="">Upload Foto Dokumen 1 : </label>
          <div
            v-if="imageListBefore.length <= 3"
            class="card border d-flex justify-content-center"
            style="height: 250px"
          >
            <div class="align-self-center mb-3">
              (MAKS 4)
              <br />
              <img src="@/assets/upload.png" width="100px" alt="" />
            </div>
            <button
              class="btn btn-sm btn-primary align-self-center"
              style="width: 15%"
              @click="uploadBefore"
            >
              Upload Disini
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="div">
            <input
              hidden
              type="file"
              class="form-control"
              ref="uploadFieldBefore"
              @change="selectFileBefore"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-3"
          v-for="(item, index) in imageListBefore"
          :key="item"
        >
          <CCardLink :href="item" target="_blank" class="">
            <CCardImg
              :src="item"
              style="object-fit: cover"
              height="auto"
              variant="full"
            />
          </CCardLink>

          <!-- <button
            class="btn btn-danger btn-sm btn-block mt-2"
            @click="deleteImageBefore('update', index)"
          >
            Hapus
          </button> -->
          <br />
        </div>
      </div>
      <!-- bagian upload -->
      

      <!-- bagian upload -->
      <div class="row">
        <div class="col text-center">
          <label for="">Upload Foto Dokumen 2 : </label>
          <div
            v-if="imageListProcess.length <= 3"
            class="card border d-flex justify-content-center"
            style="height: 250px"
          >
            <div class="align-self-center mb-3">
              (MAKS 4)
              <br />
              <img src="@/assets/upload.png" width="100px" alt="" />
            </div>
            <button
              class="btn btn-sm btn-primary align-self-center"
              style="width: 15%"
              @click="uploadProcess"
            >
              Upload Disini
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="div">
            <input
              hidden
              type="file"
              class="form-control"
              ref="uploadFieldProcess"
              @change="selectFileProcess"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-3"
          v-for="(item, index) in imageListProcess"
          :key="item"
        >
          <CCardLink :href="item" target="_blank" class="">
            <CCardImg
              :src="item"
              style="object-fit: cover"
              height="auto"
              variant="full"
            />
          </CCardLink>

          <!-- <button
            class="btn btn-danger btn-sm btn-block mt-2"
            @click="deleteImageProcess('update', index)"
          >
            Hapus
          </button> -->
          <br />
        </div>
      </div>
      <!-- bagian upload -->

      <!-- bagian upload -->
      <!-- <div class="row">
        <div class="col text-center">
          <label for="">Upload Foto Dokumen 3 : </label>
          <div
            v-if="imageListAfter.length <= 3"
            class="card border d-flex justify-content-center"
            style="height: 250px"
          >
            <div class="align-self-center mb-3">
              (MAKS 4)
              <br />
              <img src="@/assets/upload.png" width="100px" alt="" />
            </div>
            <button
              class="btn btn-sm btn-primary align-self-center"
              style="width: 15%"
              @click="uploadAfter"
            >
              Upload Disini
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="div">
            <input
              hidden
              type="file"
              class="form-control"
              ref="uploadFieldAfter"
              @change="selectFileAfter"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-3"
          v-for="(item, index) in imageListAfter"
          :key="item"
        >
          <CCardLink :href="item" target="_blank" class="">
            <CCardImg
              :src="item"
              style="object-fit: cover"
              height="auto"
              variant="full"
            />
          </CCardLink>

          <button
            class="btn btn-danger btn-sm btn-block mt-2"
            @click="deleteImageAfter('update', index)"
          >
            Hapus
          </button>
          <br />
        </div>
      </div> -->
      <!-- bagian upload -->

      <template slot="footer">
        <div>
          <button @click="cancel" class="btn btn-secondary mr-3">Batal</button>

          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah Pengaduan
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update Pengaduan
          </button>
        </div>
      </template>
    </CModal>

    <CModal
      size="lg"
      title="Import Data"
      centered
      color="primary"
      :show.sync="createModalImport"
    >
        <div>
          <p>Contoh Format Import :
          <a href="format_import_prasarana.xlsx" download>
            Download Format Excel
          </a>
        </p>
        </div>
        <input
            id="import"
            type="file"
            class="form-control"
            ref=""
            @change="selectFileImport"
          />
        <template slot="footer">
        <div>
          <button @click="cancelImport" class="btn btn-secondary mr-3">Batal</button>

          <button @click="importData" class="btn btn-primary">
            Proses Import
          </button>
        </div>
      </template>
    </CModal>

      <!-- <CModal
          size="xl"
          title="Paket Pekerjaan Progress"
          centered
          color="primary"
          :show.sync="createModalProgress"
        >
        <div class="row">
          <div class="col">
            <button @click="addDetail" class="btn btn-primary mb-3" v-if="!exist_progress">
              Tambah 
            </button>
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <td>No.</td>
                  <td style="width: 150px">Kegiatan</td>
                  <td>Minggu Ke</td>
                  <td>Progress Rencana (%)</td>
                  <td>Kumulatif Rencana (%)</td>
                  <td>Progress Realisasi (%)</td>
                  <td>Kumulatif Realisasi (%)</td>
                  <td>Deviasi</td>
                  <td></td>
                </tr>
                <tr v-for="(detail, index) in form.paket_pekerjaan_progress" :key="detail.id">
                  <td>{{ index +1 }}.</td>
                  <td style="width: 150px">
                     <CInput :disabled="exist_progress"
                        v-model="detail.kegiatan"
                      />
                  </td>
                  <td>M{{ index  }}</td>
                  <td>
                    <CInput :disabled="exist_progress"
                        v-model="detail.progress_rencana"
                      />
                  </td>
                  <td>{{ hitungKumulatif(index) }}</td>
                  <td>
                    <CInput :disabled="!exist_progress"
                        v-model="detail.progress_realisasi"
                      />
                  </td>
                  <td>{{ hitungKumulatifRealisasi(index, detail.progress_realisasi) }}</td>
                  <td>{{ hitungDeviasi(index) }}</td>
                  <td>
                    <button :hidden="exist_progress" @click="deleteDetail(index)" class="btn btn-danger mb-3">
                      Hapus
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <template slot="footer">
        <div>
          <button @click="cancelProgress" class="btn btn-secondary mr-3">Batal</button>
          <button @click="updateProgress" class="btn btn-primary">
            Update Progress
          </button>
        </div>
      </template>
      </CModal> -->

      <CModal size="xl"
          title="Kurva S"
          centered
          color="primary"
          :show.sync="createModalKurva">
          <CChartLineExample :recanaPoints="rencana_points" :realisasiPoints="realisasi_points" :labels="label_points" :kegiatanPoints="kegiatan_points" :deviasiPoints="deviasi_points" />
          <br />
          <table class="table">
            <thead>
              <tr>
                <td>
                  KET
                </td>
                <td v-for="(label) in label_points" :key="label">
                  <b>{{ label }}</b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Target
                </td>
                <td v-for="(label, index) in rencana_points" :key="label">
                 <b :style="[(getRealiasi(index) == 1 || index == 0) ? {'color': 'blue'} : {'color': 'black'}]">{{ label }}%</b> 
                </td>
              </tr>
              <tr>
                <td>
                  Realisasi
                </td>
                <td v-for="(label, index) in realisasi_points" :key="label">
                  <b :style="[(getRealiasi(index) == 1 || index == 0) ? {'color': 'blue'} : {'color': 'black'}]">{{ label }}%</b> 
                </td>
              </tr>
              <tr>
                <td>
                  Deviasi
                </td>
                <td v-for="label in deviasi_points" :key="label">
                  <b :style="[label >= 0 ? {'color': 'black'} : {'color': 'red'}]">{{ label }}%</b>
                </td>
              </tr>
            </tbody>
          </table>
      </CModal>
  </div>
</template>

<script>
import * as data from "../../model/paket-pekerjaan-public"
import { uploadImage } from "@/utils/fileUpload"
import FileSaver from "file-saver"
import CChartLineExample from "../charts/CChartLineExample.vue";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import formatNumber from '../../directives/formatNumber';

// Impor gambar marker lokal
import markerIcon from '@/assets/marker.png';

// Atur icon default untuk marker
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon,
  iconUrl: markerIcon,
});

export default {
  directives: {
    formatNumber
  },
  data() {
    return {
      map: null,
      marker: null,
      provider: new OpenStreetMapProvider(),
      searchQuery: '',
      kecamatans: [],
      jenis_pekerjaans: [],
      category: [],
      createModal: false,
      createModalImport: false,
      createModalProgress: false,
      fields: [],
      isUpdate: false,
      items: [],
      imageListAfter: [],
      imageListProcess: [],
      imageListBefore: [],
      selectedItems: [],
      isSelectedAll: false,
      selectedAction: 0,
      page: 1,
      total: 0,
      form: {
        paket_pekerjaan_progress: [],
        longitude: "",
        latitude: ""
      },
      user: JSON.parse(localStorage.getItem("user")),
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 50,
        page: 1,
        type: [0, 1],
        keyword: "",
        year: "2024",
        kecamatan: "",
        jenis_pemilihan: "",
        jenis_lahan: "",
        jenis_pekerjaan: "Pengaduan"
      },
      isSearching: false,
      searchOn: '',
      _progress: {},
      exist_progress: false,
      createModalKurva: false,
      rencana_points: [],
      realisasi_points: [],
      label_points: [],
      kegiatan_points: [],
      deviasi_points: [],
      dataApi: [],
      optionStatus: [
        'Belum Dilaksanakan',
        'Sedang Dilaksanakan',
        'Sudah Dilaksanakan',
      ].map(optionStatus => ({ name: optionStatus})),
      desa: [
'Alamendah',
'Andir',
'Arjasari',
'Babakan',
'Babakanpeuteuy',
'Bandasari.',
'Banjaran.Kulon',
'Banjaran.Wetan',
'Banjarsari',
'Banyusari',
'Baros',
'Batukarut',
'Biru',
'Bojong',
'Bojongemas',
'Bojongkunci',
'Bojongloa',
'Bojongmalaka',
'Bojongmanggu',
'Bojongsalam',
'Bojongsari.',
'Bojongsoang',
'Buahbatu',
'Bumiwangi',
'Buninagara',
'Campakamulya',
'Cangkuang',
'Cangkuang.Kulon',
'Cangkuang.Wetan',
'Ciapus',
'Ciaro',
'Cibeet',
'Cibeunying',
'Cibeureum.',
'Cibiru.Hilir',
'Cibiru.Wetan',
'Cibodas',
'Ciburial',
'Cicalengka.Kulon',
'Cicalengka.Wetan',
'Cigentur',
'Cigondewah.Hilir',
'Cihanyir',
'Cihawuk',
'Ciherang',
'Ciheulang.',
'Cijagra',
'Cikadut',
'Cikalong',
'Cikancung',
'Cikasungka',
'Cikawao',
'Cikembang.',
'Cikitu',
'Cikoneng.',
'Cikuya',
'Cilame',
'Cilampeni',
'Cilengkrang',
'Cileunyi.Kulon',
'Cileunyi.Wetan',
'Ciluluk',
'Ciluncat',
'Cimaung.',
'Cimekar',
'Cimenyan',
'Cinanggela',
'Cingcin',
'Cinunuk',
'Cipagalo',
'Cipaku',
'Cipanjalu',
'Ciparay',
'Cipedes',
'Cipelah',
'Cipeujeuh',
'Cipinang.',
'Ciporeat',
'Cisondari',
'Citaman',
'Citeureup',
'Ciwidey',
'Cukanggenteng',
'Dampit',
'Dayeuhkolot',
'Drawati',
'Dukuh',
'Gajahmekar',
'Gandasari',
'Ganjar.Sabar',
'Girimekar',
'Girimulya',
'Gunung.Leutik.',
'Haurpugur',
'Hegarmanah',
'Ibun.',
'Indragiri',
'Jagabaya',
'Jatiendah.',
'Jatisari',
'Jelegong',
'Kamasan.',
'Karamatmulya',
'Karangtunggal',
'Karyalaksana',
'Katapang',
'Kel.Baleendah.',
'Kel.Manggahang',
'Kel.Pasawahan',
'Kel.Rancaekek.Kencana',
'Kel.Sulaeman',
'Kendan',
'Kiangroke.',
'Kopo',
'Kutawaringin',
'Lagadar',
'Laksana',
'Lamajang',
'Lampegan.',
'Langensari.',
'Langonsari',
'Lebakwangi',
'Lengkong',
'Leubakmuncang',
'Linggar',
'Loa',
'Majakerta',
'Majalaya',
'Majasetra',
'Malakasari',
'Malasari.',
'Mandalahaji',
'Mandalamekar',
'Mandalasari',
'Mandalawangi',
'Mangunharja.',
'Mangunjaya',
'Margaasih',
'Margahayu.Selatan',
'Margahayu.Tengah',
'Margahurip',
'Margaluyu',
'Margamekar',
'Margamukti',
'Margamulya',
'Maruyung',
'Mekarjaya',
'Mekarlaksana',
'Mekarmaju',
'Mekarmanik.',
'Mekarpawitan',
'Mekarrahayu',
'Mekarsaluyu',
'Mekarsari.',
'Mekarwangi',
'Melatiwangi',
'Nagrak',
'Nagreg',
'Nanjung',
'Nanjungmekar',
'Narawita',
'Neglasari',
'Neglawangi',
'Nengkelan',
'Padamukti',
'Padamulya.',
'Padasuka',
'Padaulun',
'Pakutandang',
'Pamekaran',
'Pameuntasan',
'Pananjung',
'Panenjoan',
'Pangalengan.',
'Pangauban',
'Pangguh.',
'Panundaan',
'Panyadap',
'Panyirapan',
'Panyocokan',
'Parungserab',
'Pasirhuni',
'Pasirjambu',
'Pasirmulya',
'Patengan',
'Patrolsari',
'Pinggirsari',
'Pulosari',
'Rahayu',
'Rancaekek.Kulon',
'Rancaekek.Wetan',
'Rancakasumba',
'Rancakole',
'Rancamanyar',
'Rancamulya',
'Rancatungku',
'Rawabogo',
'Resmi.Tinggal.',
'Sadu',
'Sagaracipta',
'Sangiang',
'Sangkanhurip',
'Santosa',
'Sarimahi',
'Sayati',
'Sekarwangi',
'Serangmekar',
'Sindanglaya',
'Sindangpanon',
'Sindangsari',
'Solokanjeruk',
'Soreang',
'Srirahayu',
'Sudi',
'Sugihmukti',
'Sukajadi',
'Sukaluyu',
'Sukamaju',
'Sukamanah',
'Sukamantri',
'Sukamenak',
'Sukamukti',
'Sukamulya',
'Sukanagara',
'Sukapura',
'Sukarame.',
'Sukaresmi',
'Sukasari',
'Sukaweuning',
'Sumbersari.',
'Talun',
'Tanggulun.',
'Tangsimekar',
'Tanjunglaya',
'Tanjungsari',
'Tanjungwangi',
'Tarajusari.',
'Tarumajaya',
'Tegal.Sumedang',
'Tegalluar',
'Tenjolaya',
'Tribaktimulya',
'Waluya',
'Wanasuka',
'Wangisagara',
'Wargaluyu',
'Wargamekar',
'Warjabakti',
'Warnasari.'
      ].map(desa => ({ name: desa })),
    };
  },
  components: {
    CChartLineExample
  },
  methods: {
    updateNumber(event) {
      this.formattedNumber = event;
    },
    getRealiasi(index) {
      if (this.realisasi_points[index] !== 0) {
        return 1
      } else {
        return 0
      }
    },
    viewKurva(item) {
      this.createModalKurva = true
      let rencana = [];
      let realisasi = [];
      let labels = []
      let kegiatan = [];
      let deviasi = [];

      item.paket_pekerjaan_progress.forEach((element, index) => {
          rencana.push(element.kumulatif_rencana)
          realisasi.push(element.kumulatif_realisasi)
          labels.push('M' + (index))
          kegiatan.push(element.kegiatan)
          deviasi.push(element.deviasi)
      })

      this.rencana_points = rencana;
      this.realisasi_points = realisasi;
      this.label_points = labels;
      this.kegiatan_points = kegiatan;
      this.deviasi_points = deviasi;

      // console.log(item);
    },
    getKecamatan() {
      this.$store
        .dispatch("sbu/getKecamatan", this.params)
        .then((resp) => {
          this.kecamatans = resp.data
          console.log(this.kecamatans, "kecamatan")
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
        })
    },
    getCategory() {
      this.$store
        .dispatch("category/getCategory", this.params)
        .then((resp) => {
          this.category = resp.data.data
          // console.log(this.category, "category")
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
        })
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
        // this.params.keyword = '';
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },
    searchOff(){
      this.isSearching = false;
      this.params.keyword = '';
      this.getData();
    },
    submit() {
      this.form.paket_pekerjaan_befores = []
      this.form.paket_pekerjaan_processes = []
      this.form.paket_pekerjaan_afters = []

      this.imageListBefore.forEach((element) => {
        this.form.paket_pekerjaan_befores.push({
          image: element,
        })
      })

      this.imageListProcess.forEach((element) => {
        this.form.paket_pekerjaan_processes.push({
          image: element,
        })
      })

      this.imageListAfter.forEach((element) => {
        this.form.paket_pekerjaan_afters.push({
          image: element,
        })
      })

      this.form.user_id = JSON.parse(localStorage.getItem("user")).id
      this.form.opd_id = JSON.parse(localStorage.getItem("user")).role_id
      var loading = this.$loading.show()
      this.$store
        .dispatch("paket_pekerjaan/addPaketPekerjaan", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan data")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          this.$toast.error(e)
          loading.hide()
        })
    },
    edit(item) {
      this.form = item
      this.isUpdate = true
      this.createModal = true
      this.imageListBefore = this.extractGallery(item.paket_pekerjaan_befores)
      this.imageListProcess = this.extractGallery(
        item.paket_pekerjaan_processes
      )
      this.imageListAfter = this.extractGallery(item.paket_pekerjaan_afters)
    },
    cancel() {
      this.form = {
        type: 0,
      }
      this.createModal = false
    },
    update() {
      var loading = this.$loading.show()
      
      this.form.paket_pekerjaan_befores = []
      this.form.paket_pekerjaan_processes = []
      this.form.paket_pekerjaan_afters = []
      
      this.imageListBefore.forEach((element) => {
        this.form.paket_pekerjaan_befores.push({
          image: element,
        })
      })
      
      this.imageListProcess.forEach((element) => {
        this.form.paket_pekerjaan_processes.push({
          image: element,
        })
      })

      this.imageListAfter.forEach((element) => {
        this.form.paket_pekerjaan_afters.push({
          image: element,
        })
      })
      
      delete this.form.updated_at
      this.form.nilai_kontrak = parseInt(this.form.nilai_kontrak)
      this.form.status_pekerjaan = this.form.status_pekerjaan.name;
      console.log(this.form);
      this.$store
        .dispatch("paket_pekerjaan/updatePaketPekerjaan", {
          id: this.form.id,
          data: this.form,
        })
        .then(() => {
          this.$toast.success("Berhasil merubah data ")
          loading.hide()
          this.createModal = false
          this.form = {
            type: 0,
          }
          this.getData()
        })
        .catch((e) => {
          this.$toast.error(e)
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("paket_pekerjaan/deletePaketPekerjaan", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data ")

            this.form = {
              type: 0,
            }
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      console.log(this.params);
      this.$store
        .dispatch("paket_pekerjaan/getPaketPekerjaan", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
          loading.hide()
        })
    },
    addModal() {
      this.isUpdate = false
      this.createModal = true
      this.imageList = []
      this.form = {}
      // this.getKecamatan()
    },
    addModalImport() {
      this.createModalImport = true
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
    uploadAfter() {
      this.$refs.uploadFieldAfter.click()
    },
    selectFileAfter(event) {
      this.file = event.target.files[0]
      var loading = this.$loading.show()
      uploadImage(this.file)
        .then((resp) => {
          this.imageListAfter.push(resp)
          loading.hide()
          alert("File berhasil diupload !!")
        })
        .catch((e) => {
          loading.hide()
          alert("Terjadi kesalahan !! | " + e)
        })
    },
    uploadProcess() {
      this.$refs.uploadFieldProcess.click()
    },
    selectFileImport(event) {
      this.file = event.target.files[0];
    },
    cancelImport() {
      this.file = null;
      document.getElementById('import').value = '';
    },
    importData() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("paket_pekerjaan/import", this.file)
        .then((res) => {
          this.$toast.success(res.data.message);
          loading.hide();
          this.createModalImport = false;
          this.form = {
          };
          this.getData();
        })
        .catch((e) => {
          console.log(e)
          this.$toast.error(e);
          loading.hide();
        });
    },
    selectFileProcess(event) {
      this.file = event.target.files[0]
      var loading = this.$loading.show()
      uploadImage(this.file)
        .then((resp) => {
          this.imageListProcess.push(resp)
          loading.hide()
          alert("File berhasil diupload !!")
        })
        .catch((e) => {
          loading.hide()
          alert("Terjadi kesalahan !! | " + e)
        })
    },
    uploadBefore() {
      this.$refs.uploadFieldBefore.click()
    },
    selectFileBefore(event) {
      console.log('kesini')
      this.file = event.target.files[0]
      var loading = this.$loading.show()
      uploadImage(this.file)
        .then((resp) => {
          this.imageListBefore.push(resp)
          loading.hide()
          alert("File berhasil diupload !!")
        })
        .catch((e) => {
          loading.hide()
          alert("Terjadi kesalahan !! | " + e)
        })
    },
    deleteImageBefore(state, index) {
      this.imageListBefore.splice(index, 1)
    },
    deleteImageProcess(state, index) {
      this.imageListProcess.splice(index, 1)
    },
    deleteImageAfter(state, index) {
      this.imageListAfter.splice(index, 1)
    },
    extractGallery(item) {
      let data = []
      item.forEach((element) => {
        data.push(element.image)
      })
      return data
    },
    check(item) {
      if (item.select) {
        this.selectedItems.push(item.id)
      } else {
        const index = this.selectedItems.indexOf(item.id)
        this.selectedItems.splice(index, 1)
      }
    },
    checkAll() {
      this.getData()
    },
    changeActionSelected() {
      console.log(this.selectedAction)
      switch (Number(this.selectedAction)) {
        case 1:
          console.log("deleted")
          this.deleteSelected("delete")
          break
        case 2:
          console.log("export excel")
          this.exportExcel("export_excel")
          break
        case 3:
          console.log("export pdf")
          this.exportPDF()
          break
      }
    },
    deleteSelected(action) {
      var loading = this.$loading.show()
      this.$store
        .dispatch("paket_pekerjaan/selectedAction", {
          action: action,
          data: this.selectedItems,
        })
        .then((resp) => {
          this.$toast.success("Item Selected Berhasil diproses")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
          loading.hide()
        })
    },
    openModalExcel() {
      this.exportModal = true
      this.exportType = "Export Excel"
    },
    openModalPDF() {
      this.exportModal = true
      this.exportType = "Export PDF"
    },
    exportExcel(action) {
      var loading = this.$loading.show()
      this.$store
        .dispatch("paket_pekerjaan/exportReport", {
          data: this.selectedItems,
        })
        .then((resp) => {
          loading.hide()
          FileSaver.saveAs(resp.data, "Prasarana_Pertanian_")
          this.exportModal = false
          this.exportDataParams = {}
        })
        .catch((e) => {
          this.$toast.error(e)
          loading.hide()
        })
    },
    exportPDF() {
      this.$router.push({
        name: "PaketPekerjaanExportPDF",
        query: { data: this.selectedItems },
      })
    },
    progress(data) {
      this._progress = data;
      this.form.paket_pekerjaan_progress = this._progress.paket_pekerjaan_progress;
      if (this._progress.paket_pekerjaan_progress.length > 0) {
        this.exist_progress = true
      } else {
        this.exist_progress = false
      }
      this.createModalProgress = true;
    },
    addDetail() {
      this.form.paket_pekerjaan_progress.push({
        paket_pekerjaan_id: this._progress.id,
        kegiatan: "",
        minggu_ke: 0,
        progress_rencana: 0,
        kumulatif_rencana: 0,
        progress_realisasi: 0,
        kumulatif_realisasi: 0,
        deviasi: 0
      })
    },
    deleteDetail(index) {
     this.form.paket_pekerjaan_progress.splice(index, 1);
    },
    cancelProgress() {
      this.createModalProgress = false;
      this.form.paket_pekerjaan_processes = [];
    },
    updateProgress() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("paket_pekerjaan/progress", 
        this.form)
        .then((resp) => {
          this.$toast.success("Item Selected Berhasil diproses");
          loading.hide();
          this.createModalProgress = false;
          this.form = {
            paket_pekerjaan_progress: []
          };
          this.getData();
        })
        .catch((e) => {
          this.$toast.error("gagal simpan data  \n", e);
          loading.hide();
        });
    },
    hitungKumulatif(_index) {
      let kumulatif = 0;
      this.form.paket_pekerjaan_progress.forEach((element, index) => {
        if (index <= _index) {
          kumulatif += parseInt(element.progress_rencana);
        }
      })
      this.form.paket_pekerjaan_progress[_index].kumulatif_rencana = kumulatif;
      return this.form.paket_pekerjaan_progress[_index].kumulatif_rencana;
    },
    hitungKumulatifRealisasi(_index, realisasi) {
      let kumulatif = 0;
      this.form.paket_pekerjaan_progress.forEach((element, index) => {
        if (index <= _index) {
          if (realisasi == 0) {
            kumulatif = 0;
          } else {
            kumulatif += parseInt(element.progress_realisasi);
          }
        }
      })
      this.form.paket_pekerjaan_progress[_index].kumulatif_realisasi = kumulatif;
      return this.form.paket_pekerjaan_progress[_index].kumulatif_realisasi;
    },
    hitungDeviasi(_index) {
      this.form.paket_pekerjaan_progress[_index].deviasi = this.form.paket_pekerjaan_progress[_index].kumulatif_realisasi - this.form.paket_pekerjaan_progress[_index].kumulatif_rencana;
      return this.form.paket_pekerjaan_progress[_index].deviasi;
    },
    getBackground (data) {
        return data >= 0 ? 'blue' : ``
    },
    addFromApi() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("tender_selesai/getTenderSelesai", this.params)
        .then((resp) => {
   
          this.dataApi = resp;
          this.total = 0;

          this.$store
          .dispatch("paket_pekerjaan/addPaketPekerjaanFromApi", this.dataApi)
          .then(() => {
            this.$toast.success("Berhasil menambahkan data")
            loading.hide()
            this.createModal = false
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    initMap() {
      // Inisialisasi peta
      this.form.longitude = "107.621532"
      this.form.latitude = "-7.13407"
      
      this.map = L.map('map').setView([-7.13407, 107.621532], 13);

      // Tambahkan tile layer dari OpenStreetMap
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);

      // Tambahkan marker yang bisa digeser
      this.marker = L.marker([-7.13407, 107.621532], {
        draggable: true
      }).addTo(this.map);

      // Event listener untuk mendapatkan koordinat saat marker digeser
      this.marker.on('dragend', (event) => {
        const position = event.target.getLatLng();
        this.form.longitude = position.lng;
        this.form.latitude = position.lat;
      });
    },
    async searchPlace() {
      if (this.searchQuery.length > 2) {
        const results = await this.provider.search({ query: this.searchQuery });
        if (results.length > 0) {
          const { x, y } = results[0];
          this.map.setView([y, x], 13);
          this.marker.setLatLng([y, x]);
          this.form.longitude = y;
          this.form.latitude = x;
        }
      }
    },
    updateSatuan() {
      const selectedCategory = this.category.find(
        (category) => category.name === this.form.jenis_pekerjaan
      );
      console.log(selectedCategory);
      if (selectedCategory) {
        if (selectedCategory.name.includes('Jaringan') || selectedCategory.name.includes('Jalan')) {
          this.form.satuan = 'Meter';
        } else if (selectedCategory.name.includes('Bangunan') || selectedCategory.name.includes('Pengairan')) {
          this.form.satuan = 'Unit';
        } else {
          this.form.satuan = '';
        }
      } else {
        this.form.satuan = '';
      }
      console.log(this.form.satuan);
    },
  },
  computed: {
    formattedNumber: {
      get() {
        return this.form.nilai_kontrak !== null ? this.form.nilai_kontrak.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
      },
      set(value) {
        this.form.nilai_kontrak = value.replace(/,/g, '');
      }
    },
    computedItems() {
      return this.items.map((item, index) => {
        return {
          index: index + 1 + ".",
          ...item,
          nilai_kontrak: item.nilai_kontrak
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          full_name: item.user != null ? item.user.full_name : "",
          opd: item.role != null ? item.role.name : "",
          jenis_pemilihan: item.jenis_pemilihan != null ? item.jenis_pemilihan : "",
          pemenang_tender: item.pemenang_tender != null ? item.pemenang_tender : "",
          created_at: item.created_at.slice(0, 10),
        }
      })
    },
    
  },
  mounted() {
    
    if (this.$route.query.prasarana || this.$route.query.tahun || this.$route.query.kecamatan || this.$route.query.jenis_pemilihan || this.$route.query.jenis_lahan) {
      this.params.jenis_pekerjaan = this.$route.query.prasarana;
      this.params.year = this.$route.query.tahun;
      this.params.kecamatan = this.$route.query.kecamatan;
      this.params.jenis_pemilihan = this.$route.query.jenis_pemilihan;
      this.params.jenis_lahan = this.$route.query.jenis_lahan;
      this.getData();
    } else {
      this.getData();
    }
    console.log('sini jal', this.$route);
    console.log('sini jal', this.params);


    this.initMap();
    // this.getData()
    this.getKecamatan()
    this.getCategory()

    // Tambahkan event listener untuk modal Bootstrap
    const modal = document.getElementById('mapModal');
    modal.addEventListener('shown.bs.modal', () => {
      this.map.invalidateSize();
    });
    

    // let wrapper_1 = document.querySelector('.table-striped .table-responsive');
    this.$nextTick(() => {
      let wrapper_1 = document.querySelector('.table-striped .table-responsive');
      let wrapper_2 = document.querySelector('#wrapper2');
      if (wrapper_1) {
        // Element found, you can now work with it
        wrapper_1.onscroll = function() {
          wrapper_2.scrollLeft = wrapper_1.scrollLeft;
        };
        wrapper_2.onscroll = function() {
          wrapper_1.scrollLeft = wrapper_2.scrollLeft;
        };
      } else {
        // Element not found, log an error or handle the situation accordingly
        console.error("Element not found");
      }
    });
  },

  created() {
    this.fields =
      this.user.role.name.toLowerCase() == "admin"
        ? data.fields
        : data.fields
  },
}
</script>

<style>

/* This is the size of the path of the scrollbar */
#wrapper2{
      width: 100%;
      overflow-x: scroll; 
      overflow-y:hidden;
  }

/* This div allow to make the scroll function and show the scrollbar */
  #div2 {
      height: 1px;
      overflow: scroll;
  }

  /* This is the size of the scrollbar */
  .width-scroll{
    width: 5000px;
  }

  @media only screen and (max-width: 767px) {

  /* Adjust styles for mobile display here */
  #wrapper2 {
    width: 100%;
    overflow-x: auto;
  }

  #div2 {
    height: 7px;
    overflow: scroll;
  }

  .width-scroll {
    width: 5000px;
  }

  }

</style>
